<template>
	<div class="container">
		<div class="ht20"></div>
		<div class="fill-sec">
			<div class="top-sec">
				<input placeholder="投票标题" @input="titleChange" v-model="title" class="title-input" />
				<textarea @input="descChange" v-model="desc" placeholder="补充描述"
					placeholder-class='desc-placeholder-input' class="desc-input"></textarea>
				<div class="flex-row align-center item" v-for="(item,index) in items" :key="index">
					<img src="../assets/initiate/item_minus_icon.png" class="item-icon" @click="reduceItem(index)" />
					<input placeholder="选项" maxlength="80" @input="itemChange" :data-index="index" :value="item"
						class="item-text" />
				</div>
				<div class="flex-row align-center item" @click="addItem()">
					<img src="../assets/initiate/item_add_icon.png" class="item-icon" />
					<div class="item-add-text">添加选项</div>
				</div>
			</div>

			<div class="flex-row align-center space-between deadline-date-sec">
				<div class="flex-row align-center">
					<img src="../assets/initiate/deadline_date_icon.png" class="item-icon" />
					<div class="deadline-text">截止日期</div>
				</div>
				<div class="deadline-day">{{project[0].voteEndDays}}天</div>
			</div>
			<div class="relation-tian-sec" v-if='confirmSelectTianIndex >= 0' @click="showTianModal()">
				<div class="flex-row align-center space-between deadline-date-sec">
					<div class="flex-row align-center">
						<img src="../assets/initiate/relation_icon.png" class="item-icon" />
						<div class="deadline-text">关联提案</div>
					</div>
					<img src="../assets/initiate/change_tian.png" class="change-icon" />
				</div>
				<div class="tian-con">{{proposalData.list[selectTianIndex].title}}</div>
			</div>
			<div class="flex-row align-center space-between deadline-date-sec" v-else @click="showTianModal()">
				<div class="flex-row align-center">
					<img src="../assets/initiate/relation_icon.png" class="item-icon" />
					<div class="deadline-text">关联提案</div>
				</div>
				<img src="../assets/initiate/arrow_icon.png" class="arrow-icon" />
			</div>
		</div>
		<div class="initiate-btn" @click="initiate()">发起投票</div>
		<div class="select-tian-modal" v-if="isShowTianModal">
			<div class="select-tian-sec">
				<div class="flex-row align-center select-tian-sec-top space-between">
					<div class="select-tian-sec-title">请选择提案</div>
					<div class="select-tian-sec-cancle" @click="closeTianModal()">取消</div>
				</div>
				<div class="tian-list-sec">
					<van-list v-model="proposalData.loading" :immediate-check="proposalData.immediateCheck"
						@load="getProposalList" :finished="proposalData.finished" finished-text="没有更多了"
						:error.sync="proposalData.error" error-text="请求失败，点击重新加载">
						<div class="flex-row align-center space-between tian-item"
							v-for="(item,index) in proposalData.list" :key="index">
							<div class="flex-row align-center">
								<div class="tian-item-dian"></div>
								<div class="tian-item-con">{{item.title}}</div>
							</div>
							<div class="tian-item-no-select" v-if="index != selectTianIndex" @click="selectTian(index)">
							</div>
							<div class="tian-item-select" v-else></div>
						</div>
					</van-list>
				</div>
			</div>
			<div class="confirm-tian-btn-sec">
				<div class="confirm-tian-btn" @click="confirmTian()">确认</div>
			</div>
		</div>
	</div>
</template>

<script>
	import config from '../config/config.js';
	import util from '../config/util.js';
	import Vue from 'vue';
	import Vant from 'vant';
	import 'vant/lib/index.css';

	Vue.use(Vant);
	export default {
		data() {
			return {
				project: [],
				proposalData: {
					loading: false,
					error: false,
					finish: false,
					pageNum: 1,
					pageSize: 10,
					list: [],
					immediateCheck: false,
				},
				title: '', //投票标题
				desc: '', //补充描述
				items: ['', '', ''], //选项
				confirmSelectTianIndex: -1, //已确认选择的提案下标
				selectTianIndex: -1, //已选择的提案下标，但是没有选择确认按钮
				isShowTianModal: false, //是否展示提案弹窗
			}
		},
		methods: {
			selectTian(index) {
				this.selectTianIndex = index;
			},
			confirmTian() {
				this.confirmSelectTianIndex = this.selectTianIndex;
				this.isShowTianModal = false;
			},
			closeTianModal() {
				this.isShowTianModal = false;
			},
			showTianModal() {
				this.isShowTianModal = true;
			},
			titleChange(e) {
				this.title = e.target.value;
			},
			descChange(e) {
				this.desc = e.target.value;
			},
			addItem() {
				this.items.push('')
			},
			reduceItem(index) {
				this.items.splice(index, 1)
			},
			itemChange(e) {
				let index = e.target.dataset.index;
				this.items[index] = e.target.value;
			},
			initiate() {
				const that = this;
				if (!that.title.trim()) {
					that.$toast('请输入投票标题')
					return;
				}
				if (!that.desc.trim()) {
					that.$toast('请输入补充描述')
					return;
				}
				// if (that.selectTianIndex == -1) {
				// 	that.$toast('请选择提案')
				// 	return;
				// }
				let items = [];
				if (that.items.length < 2) {
					that.$toast('请至少添加2个选项')
					return;
				} else {
					let itemNum = 0;
					for (let i in that.items) {
						if (that.items[i].trim() != '') {
							itemNum = itemNum + 1;
							items.push(that.items[i].trim());
						}
					}
					if (itemNum < 2) {
						that.$toast('请至少添加2个选项')
						return;
					}
				}
				console.log(items)
				// 提交数据到后台 todo
				let params = new FormData();
				params.append('title', that.title)
				params.append('content', that.desc)
				params.append('option', items.join('and'))
				params.append('projectId', that.project[0].id)
				params.append('proposalIds', that.selectTianIndex > -1 ? that.proposalData.list[that.selectTianIndex].id :
					'')
				params.append('proposalTitle', that.selectTianIndex > -1 ? that.proposalData.list[that.selectTianIndex]
					.title : '')
				that.$toast.loading({
					message: '发起投票中...',
					forbidClick: true,
					duration: 0,
				});
				that.axios.post(config.requestUrl + '/front/forum/publishVote', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.$toast.clear();
						that.$router.replace('/initiatesuccess')
					} else {
						that.$toast(response.data.msg);
					}
				}, response => {
					that.$toast('发起投票失败，请稍后重试');
				})
			},
			getNftProjectListByUserId() {
				const that = this
				let params = new FormData()
				that.axios.post(config.requestUrl + '/front/forum/getNftProjectListByUserId', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.project = response.data.obj;
						that.getProposalList();
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								this.$router.replace('/')
							}, 1200)
						}
						that.$toast.fail(response.data.msg);
					}
				}, response => {
					that.$toast.fail('获取失败，请稍后重试');
				})
			},
			getProposalList() {
				const that = this;
				if (that.proposalData.loading) return;
				let params = new FormData()
				params.append('pageNum', this.proposalData.pageNum)
				params.append('pageSize', this.proposalData.pageSize)
				params.append('proposalStatus', 8)
				params.append('projectId', this.project[0].id)
				that.axios.post(config.requestUrl + '/front/forum/getProposalList', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.$toast.clear();
						let newList = response.data.obj.list;
						for (let i in newList) {
							newList[i].content = util.getPlainText(newList[i].content)
							newList[i].userAddress = util.formatAddress(newList[i].userAddress)
							newList[i].currentSupportRate = (newList[i].currentSupportRate).toFixed(2);
						}
						if (newList.length < that.proposalData.pageSize) that.proposalData.finished = true;
						that.proposalData.pageNum = that.proposalData.pageNum + 1;
						that.proposalData.loading = false
						that.proposalData.list = that.proposalData.list.concat(newList);
						console.log(that.proposalData)
					} else {
						that.proposalData.error = true
						that.$toast.fail(response.data.msg);
					}
					that.proposalData.loading = false;
				}, response => {
					that.proposalData.error = true
					that.proposalData.loading = false;
					that.$toast.fail('获取失败');
				})
			},
		},
		mounted() {
			this.getNftProjectListByUserId();
		},
		beforeRouteLeave(to, from, next) {
			if (this.isShowTianModal) {
				this.isShowTianModal = false;
			} else {
				next();
			}
			// console.log(to);
			// console.log(from)
			// next();
		},
	}
</script>

<style scoped="scoped">
	.container {
		width: 100%;
		height: 100%;
		background: #F5F5F5;
	}

	.ht20 {
		height: 20px;
	}

	.fill-sec {
		padding: 0 20px;
		padding-bottom: 300px;
		background: #F5F5F5;
	}

	.top-sec {
		width: 710px;
		background: #FFFFFF;
		border-radius: 10px;
		padding: 0 30px;
		box-sizing: border-box;
	}

	input,
	textarea {
		border: none;
	}

	.title-input {
		width: 650px;
		height: 110px;
		box-sizing: border-box;
		border-bottom: 1px solid #F5F5F5;
		font-size: 32px;
		font-weight: bold;
		color: #000000;
		line-height: 110px;
		padding-left: 1px;
	}

	textarea::placeholder {
		font-size: 28px;
		color: rgba(0, 0, 0, 0.5) !important;
		line-height: 32px;
	}

	.desc-input {
		font-size: 28px;
		color: rgba(0, 0, 0, 0.85);
		line-height: 46px;
		width: 650px;
		padding: 35px 0 20px 0;
		min-height: 55px;
		padding-left: 1px;
		box-sizing: border-box;
		height: auto;
		border-bottom: 1px solid #F5F5F5;
	}

	.item {
		width: 650px;
		height: 100px;
	}

	.item-icon {
		width: 35px;
		height: 35px;
	}

	.item-text {
		font-size: 28px;
		color: #000000;
		line-height: 32px;
		margin-left: 21px;
		width: 594px;
		height: 100px;
		border-bottom: 1px solid #F5F5F5;
		box-sizing: border-box;
	}

	.item-add-text {
		font-size: 28px;
		color: #F7B500;
		line-height: 32px;
		margin-left: 21px;
	}

	.deadline-date-sec {
		width: 710px;
		height: 100px;
		background: #FFFFFF;
		border-radius: 10px;
		padding: 0 30px;
		box-sizing: border-box;
		margin-top: 20px;
	}

	.deadline-text {
		font-size: 28px;
		color: #000000;
		line-height: 40px;
		margin-left: 21px;
	}

	.deadline-day {
		font-size: 28px;
		color: #000000;
		line-height: 40px;
	}

	.initiate-btn {
		width: 690px;
		height: 80px;
		background: #F7B500;
		border-radius: 40px;
		font-size: 32px;
		font-weight: bold;
		color: #000000;
		line-height: 80px;
		letter-spacing: 1px;
		text-align: center;
		position: fixed;
		bottom: 56px;
		left: 0;
		right: 0;
		margin: 0 auto;
	}

	.arrow-icon {
		width: 17px;
		height: 30px;
	}

	.change-icon {
		width: 130px;
		height: 50px;
	}

	.tian-con {
		width: 650px;
		font-size: 28px;
		color: #000000;
		line-height: 40px;
		padding-top: 17px;
		padding-left: 30px;
		border-top: 3px solid #F5F5F5;
	}

	.relation-tian-sec {
		width: 710px;
		min-height: 400px;
		background: #FFFFFF;
		border-radius: 30px;
	}

	/* 选择提案弹窗 */
	.select-tian-modal {
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.7);
		position: fixed;
		left: 0;
		top: 0;
	}

	.select-tian-sec {
		width: 750px;
		height: 1128px;
		background: #F5F5F5;
		border-radius: 20px 20px 0px 0px;
		position: fixed;
		left: 0;
		bottom: 0;
		padding: 36px 30px 0 30px;
		box-sizing: border-box;
	}

	.select-tian-sec-top {}

	.select-tian-sec-title {
		font-size: 28px;
		font-weight: bold;
		color: #000000;
		line-height: 28px;
		margin-left: 275px;
	}

	.select-tian-sec-cancle {
		font-size: 28px;
		color: #000000;
		line-height: 28px;
		align-items: flex-end;
	}

	.tian-list-sec {
		width: 690px;
		height: 1034px;
		overflow-y: scroll;
		padding-bottom: 200px;
		box-sizing: border-box;
		margin-top: 30px;
	}

	.tian-item {
		width: 690px;
		background: #FFFFFF;
		border-radius: 30px;
		padding: 14px 40px 14px 29px;
		box-sizing: border-box;
		margin-bottom: 20px;
	}

	.tian-item-dian {
		width: 10px;
		height: 10px;
		background: #6236FF;
		border-radius: 50%;
	}

	.tian-item-con {
		width: 560px;
		font-size: 28px;
		color: #000000;
		line-height: 40px;
		margin-left: 11px;
	}

	.tian-item-no-select {
		width: 20px;
		height: 20px;
		border: 1px solid #6236FF;
		border-radius: 50%;
	}

	.tian-item-select {
		width: 20px;
		height: 20px;
		background: #F7B500;
		border: 1px solid #6236FF;
		border-radius: 50%;
	}

	.confirm-tian-btn-sec {
		position: fixed;
		left: 0;
		bottom: 0;
		width: 750px;
		height: 180px;
		background: #FFFFFF;
		box-shadow: inset 0px 1px 0px 0px rgba(0, 0, 0, 0.12);
	}

	.confirm-tian-btn {
		width: 690px;
		height: 80px;
		background: #F7B500;
		border-radius: 40px;
		font-size: 32px;
		font-weight: bold;
		color: #000000;
		line-height: 80px;
		letter-spacing: 1px;
		text-align: center;
		margin: 45px auto 0 auto;
	}
</style>